import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

const RegistrationContainer = ({
  children,
  registrationFormBenefit,

  steps = null,
  step = 0,
  showHint = true,
  useStraightForm = false,
  rightPaneTitle = null,
  rightPaneContent = null,
}) => {
  const { t, i18n } = useTranslation();
  return (
    <section className="mainContents" style={{ backgroundImage: "none", background: "white" }}>
      <div className="container conWrapper">
        <div className="row">
          <div className="col-12 contents registationForm">
            <div className={`formLeft edit-form col-12`}>
              {children}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
const mapStateToProps = state => ({
  registrationFormBenefit: state.shared.registration_form_benefit,
});

export default connect(mapStateToProps)(RegistrationContainer);
