import { useHistory } from "react-router-dom";
import {useEffect, useState} from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import FormLabel from "./FormLabel";

const MembershipType = ({ schoolTypes, formData, handleFormData, handleFileUpload, active = true, instruments, registration }) => {
  const navigate = useHistory();
  const { t, i18n } = useTranslation();

  return (
    <div id="formPart3" className={`formSteps step3 col-12 flex-wrap ${active ? 'curStep' : ''}`}>
      <div className="regBoxes d-flex">
        {
            registration &&
            registration[0] &&
            registration[0].content && (
                <a className="regBox"
                   onClick={() => navigate.push(`/${i18n.language}/registration-free`)}
                   dangerouslySetInnerHTML={{
                     __html:
                         i18n.language === 'tc'
                             ? registration[0].content['tc']
                             : i18n.language === 'sc'
                                 ? registration[0].content['sc']
                                 : registration[0].content['en'],
                   }}
                >
                </a>
            )}

        {
            registration &&
            registration[1] &&
            registration[1].content && (
                <a
                    className="regBox"
                    target="_blank"
                    onClick={() => navigate.push(`/${i18n.language}/registration-red`)}
                    dangerouslySetInnerHTML={{
                      __html:
                          i18n.language === 'tc'
                              ? registration[1].content['tc']
                              : i18n.language === 'sc'
                                  ? registration[1].content['sc']
                                  : registration[1].content['en'],
                    }}
                ></a>
            )
        }
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  instruments: state.shared.instruments,
  schoolTypes: state.shared.school_types,
  registration: state.shared.registration,

});

export default connect(mapStateToProps)(MembershipType);
