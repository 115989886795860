import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import {useEffect, useState} from "react";
import { registrationForm } from "../services/enums";
import {getExistingMemberProfile, setProfileIsMigrated, verifyEmailOrMobile} from "../services/user";
import {parseFormData} from "../services/helpers";
import ReactLoadingSpinner from "../components/ReactLoadingSpinner";
import Popup from "../components/Popup";
import './Registration.css';

const Registration = ({ profile, registration }) => {
    const navigate = useHistory();
    const { t, i18n } = useTranslation();
    const { loginWithRedirect } = useAuth0();
    const [formData, setFormData] = useState<any>({});
    const [isLoading, setIsLoading] = useState(false);
    const [validationError, setValidationError] = useState(null);

    //set html_title
    useEffect(() => { 
        document.title = t('html_title.registration')+' | '+  t('html_title.home');
    }, []);
    useEffect(() => { 
        document.title = t('html_title.registration')+' | '+  t('html_title.home');
    }, [i18n.language]);

    useEffect(()=>{
        if (profile?.is_submitted_profile == registrationForm.submitted)  navigate.push(`/${i18n.language}/member-detail`);
    },[profile])

    const submit = async () => {
        setIsLoading(true);

        const res = await verifyEmailOrMobile(formData);
        if (res.success && res.message == 'Existing member found') {
            navigate.push(`/${i18n.language}/registration-existing`);
        }
        else if (res.success && res.message == 'User found') {
            loginWithRedirect({
                type: 'login',
                lang: i18n.language,
                redirectUri: `${window.location.origin}/login`,
                appState: {
                    page: navigate.location.pathname == ('/' + i18n.language + '/registration-sc') // Redirect user back to SC reg with token
                        ? (navigate.location.pathname + navigate.location.hash)
                        : navigate.location.pathname
                }
            });
        }
        else {
            await loginWithRedirect({
                type: 'register',
                lang: i18n.language,
                redirectUri: `${window.location.origin}/login`, // May need to change this in the future
                appState: {
                    page: `/${i18n.language}/registration-free`
                }
            })
        }
        setIsLoading(false);
    }

    const handleFormData = (e) => {
        const { name, value } = parseFormData(e);
        setFormData({ ...formData, [name]: value });
    }

    return (
        <>
            {validationError && <Popup
                title={''} // temp removed error.validation_error
                message={validationError}
                buttons={[
                    {
                        label: t('button.close'),
                        callback: () => {
                            setValidationError(null);
                        }
                    }
                ]}
            />}
        {isLoading && <ReactLoadingSpinner />}

        <section className="mainContents" style={{ backgroundImage: "none", background: "white" }}>
            <div className="container conWrapper" style={{ padding: window.innerWidth < 576 ? '0 50px' : undefined }}>
                <div className="row">
                    <div className="col-sm-12">
                        <div className='contents'>
                            <h1 className="title1 text-center w-100">{t('registration_common.join_club_bravo')}</h1>
                            <div className="formCol col-12 col-xl-8">
                                <p className="formTitle">
                                    {t('landing_page.email_or_mobile')}
                                </p>
                                <input
                                    name="email_or_mobile"
                                    className="form-control formInput"
                                    type="text"
                                    onChange={handleFormData}
                                    value={formData?.email_or_mobile || ''}
                                />
                            </div>
                            <div className="col-12 formCol d-flex">
                                <label
                                    id="btnSubmit"
                                    className="btn nextStep"
                                    onClick={submit}
                                >
                                    {t('button.submit')}
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    )
};

const mapStateToProps = state => ({
    profile: state.shared.profile,
    registration: state.shared.registration,
});

export default connect(mapStateToProps)(Registration);
