import moment from "moment";
import { getI18n } from "react-i18next";

/**
 * Format Date
 * @param date expected YYYY-MM-DD
 * @returns string|null
 */
export function dateFormat(date: string) {
    if (!date) return null;

    return moment(date).format(getI18n().language === 'en'
        ? `D MMM YYYY`
        : `YYYY年M月D日`
    );
}

/**
 * Format Dob Date
 * @param date expected YYYY-MM-DD
 * @returns string|null
 */
export function dateFormatDob(date: string) {
    if (!date) return null;

    return moment(date).format(getI18n().language === 'en'
        ? `MMM YYYY`
        : `YYYY年M月`
    );
}

/**
 * Parse Error Message From Backend with translated message
 * If fail to found error pattern, original error message will be returned
 * @param error string
 * @returns string
 */
export function parseValidationError(error: string) {
    if (!error) return null;
    const t = getI18n().t;

    for (let e of [
        { key: 'invalid', reg: /(.*) is NOT valid/i },
        { key: 'required', reg: /(.*) is required/i },
        { key: 'missing', reg: /(.*) is missing/i },
        { key: 'already_been_used', reg: /(.*) has already been used/i },
    ]) {
        const match = e.reg.exec(error);
        if (match) {
            const field = match.at(1).toLowerCase().replaceAll(' ', '_');
            return t(`error.${e.key}`, {
                field: t(`landing_page.${field}`)
            });
        }

        return t(error);
    }
}

export function validateEnglishName(name, input) {
    if (!input) return null;
    const t = getI18n().t;

    var validEnglishNameRegex = /^([ \u00c0-\u01ffa-zA-Z'\-])+$/;
    if (input && input.match(validEnglishNameRegex)) {
        return null;
    } else {
        return t(`error.invalid`, {
            field: t(`landing_page.${name}`)
        });
    }
}

export function validateChineseName(name, input) {
    if (!input) return null;
    const t = getI18n().t;

    var validChineseNameRegex = /[\u4E00-\u9FFF]/;
    var validNumberRegex = /[0-9]/;
    var validCharRegex = /[a-z]+/g;
    var spaceRegex = /\s/;
    var nonWordChar = /[-!$%^&*()_+|~=`{}\[\]:";'<>?,.\/]/;
    if (
        input.match(validChineseNameRegex) &&
        (input.match(validNumberRegex) || input.match(validCharRegex) || input.match(spaceRegex) || input.match(nonWordChar))
    ) {
        return t('error.invalid', { field: t(`landing_page.${name}`) });
    }

    return null;
}

export function validateRequired(name, value, ...rest) {
    const t = getI18n().t;
    const isNull = v => (typeof v === 'undefined') || v === null || v === false || v === '';

    return [...rest, value].map(isNull).indexOf(true) > -1
        ? t('error.required', { field: t(`landing_page.${name}`) })
        : null;
}

export function validateEmail(name, value) {
    if (!value) return null;
    const t = getI18n().t;

    var validEmailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (!value.match(validEmailRegex)) {
        return t('error.invalid', { field: t(`landing_page.${name}`) });
    }

    return null;
}
export function validateMobile(name, value) {
    if (!value) return null;
    const t = getI18n().t;

    var validMobileRegex = /^[0-9]+$/;
    if (!value.match(validMobileRegex)) {
        return t('error.invalid', { field: t(`landing_page.${name}`) });
    }

    return null;
}

export function validateDob(name, year, month) {
    const t = getI18n().t;

    if ((year && !month) || (!year && month)) {
        return t(`error.invalid`, { field: t(`landing_page.${name}`) });
    }

    return null;
}

export function getAddress(address, countries = []) {
    if (!address) return '';

    return [
        'flat_or_unit',
        'floor',
        'block_or_phase',
        'house_or_building_estate',
        'road_or_street',
        'district',
        'country',
    ]
        .map(v => {
            if (
                v === 'country' &&
                address[v] &&
                countries.length > 0
            ) {
                const id = parseInt(address[v]);
                const country = countries.find(v => v.id === id);
                return country
                    ? country.name[getI18n().language]
                    : address[v];
            }

            return address[v]
        })
        .filter(v => !!v)
        .join(', ');
}

export const salutationOptions = [
    { name: 'landing_page.prof', value: 'Prof' },
    { name: 'landing_page.dr', value: 'Dr' },
    { name: 'landing_page.mr', value: 'Mr' },
    { name: 'landing_page.mrs', value: 'Mrs' },
    { name: 'landing_page.ms', value: 'Ms' },
];

export const monthOptions = Array.from({ length: 12 }, (_, i) => i + 1);

const currentYear = new Date().getFullYear();
export const yearOptions = Array.from({ length: 120 }, (_, i) => currentYear - i - 1);

export const genderOptions = [
    { name: 'landing_page.male', value: 'male' },
    { name: 'landing_page.female', value: 'female' },
    { name: 'landing_page.other', value: 'other' },
];

export const countryCodeOptions = [
    { name: '+852', value: '852' },
    { name: '+86', value: '86' },
];

export const countryOptions = [
    { name: 'select', value: '' },
    { name: 'hong_kong_sar', value: 'hong_kong_sar' },
    { name: 'taiwan', value: 'taiwan' },
    { name: 'china', value: 'china' },
];

export const patchUserProfile = (profile) => {
    if (!profile) return;

    let data = { ...profile };

    if (data.dob) {
        const date = data.dob.split('-');
        data.year = date[0];
        data.month = parseInt(date[1]);
    }

    // FIXME: which address should be used?
    // FIXME: also the shipping_address_1 naming and structure is strange
    if (data.shipping_address_1 && data.shipping_address_1?.at(0)) {
        data.flat_or_unit = data.shipping_address_1?.at(0)?.memberProfile.flat_or_unit;
        data.floor = data.shipping_address_1?.at(0)?.memberProfile.floor;
        data.block_or_phase = data.shipping_address_1?.at(0)?.memberProfile.block_or_phase;
        data.house_or_building_estate = data.shipping_address_1?.at(0)?.memberProfile.house_or_building_estate;
        data.road_or_street = data.shipping_address_1?.at(0)?.memberProfile.road_or_street;
        data.district = data.shipping_address_1?.at(0)?.memberProfile.district;
        data.country = data.shipping_address_1?.at(0)?.memberProfile.country;
    }

    for (let key in data) {
        data[key] = data[key] || '';
    }

    return data;
}

export function parseFormData({ target }) {
    const name = target.name;
    let value = target.value;

    if (target.type === 'checkbox') {
        value = target.checked;
    }

    return { name, value };
}

export function getDebugUser() {
    return process.env.REACT_APP_DEBUG_DUMMY_LOGIN === 'true'
        ? {
            email: process.env.REACT_APP_DEBUG_EMAIL,
            sub: process.env.REACT_APP_DEBUG_PASSWORD,
            email_verified: process.env.REACT_APP_DEBUG_EMAIL_VERIFY,
        }
        : null;
}

export const useOldMember = () => {
    const key = 'hkphil_old_member';
    return {
        getOldMember: () => JSON.parse(sessionStorage.getItem(key) || null),
        setOldMember: (data) => sessionStorage.setItem(key, JSON.stringify(data)),
        clearOldMember: () => sessionStorage.removeItem(key),
    };
};
export const useSCTier = () => {
    const tierKey = 'sc_tier';
    const tokenKey = 'sc_token';
    return {
        // SCTier
        getSCTier: () => JSON.parse(sessionStorage.getItem(tierKey) || null),
        setSCTier: (data) => sessionStorage.setItem(tierKey, JSON.stringify(data)),
        clearSCTier: () => sessionStorage.removeItem(tierKey),
        // SCToken
        getSCToken: () => JSON.parse(sessionStorage.getItem(tokenKey) || null),
        setSCToken: (data) => sessionStorage.setItem(tokenKey, JSON.stringify(data)),
        clearSCToken: () => sessionStorage.removeItem(tokenKey),
    };
};

export const useFreeUpgradeTier = () => {
    const tierKey = 'free_upgrade_tier';
    const tokenKey = 'free_tier_upgrade_token';
    return {
        // FreeUpgradeTier
        getFreeUpgradeTier: () => {
            const data = JSON.parse(sessionStorage.getItem(tierKey) || null);
            console.log('getFreeUpgradeTier:', data);
            return data;
        },
        setFreeUpgradeTier: (data) => {
            console.log('setFreeUpgradeTier:', data);
            sessionStorage.setItem(tierKey, JSON.stringify(data));
        },
        clearFreeUpgradeTier: () => {
            console.log('clearFreeUpgradeTier');
            sessionStorage.removeItem(tierKey);
        },
        // FreeUpgradeToken
        getFreeTierUpgradeToken: () => {
            const data = JSON.parse(sessionStorage.getItem(tokenKey) || null);
            console.log('getFreeTierUpgradeToken:', data);
            return data;
        },
        setFreeTierUpgradeToken: (data) => {
            console.log('setFreeTierUpgradeToken:', data);
            sessionStorage.setItem(tokenKey, JSON.stringify(data));
        },
        clearFreeTierUpgradeToken: () => {
            console.log('clearFreeTierUpgradeToken');
            sessionStorage.removeItem(tokenKey);
        },
    };
};export const toHash = str => str.replaceAll(' ', '-').toLowerCase();
