import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./HamburgerMenu.css";
import i18n from "i18next";
import {useTranslation} from "react-i18next";

const Navbar = ({ profile }) => {
    const [isMobile, setIsMobile] = useState(false);
    const [loggedIn, setLoggedIn] = useState(false);
    const [showSubMenu, setShowSubMenu] = useState('');
    const [activeMenuItem, setActiveMenuItem] = useState('');
    const { t, i18n } = useTranslation();

    useEffect(() => {
        if (!profile || Object.keys(profile).length === 0) return;
        else {
            setLoggedIn(true);
        }
    }, [profile]);

    const toggleSubMenu = (menu) => {
        if (showSubMenu === menu) {
            setShowSubMenu('');
            setActiveMenuItem('');
        } else {
            setShowSubMenu(menu);
            setActiveMenuItem(menu);
        }
    };

    const closeMenu = () => {
        setIsMobile(false);
    };

    return (
        <nav className="navbar">
            <ul className={`nav-links-mobile ${isMobile ? "show" : ""}`}>
                <li onClick={() => toggleSubMenu('club-bravo')}>
                    <p className={`menu ${activeMenuItem === 'club-bravo' ? 'active' : ''}`}><strong>{t('header.what_is_club_bravo')}</strong><span className={`arrow ${showSubMenu === 'club-bravo' ? 'flip' : ''}`}>{showSubMenu === 'club-bravo' ? <i className="fas fa-chevron-up"></i> : <i className="fas fa-chevron-down"></i>}</span></p>
                    {showSubMenu === 'club-bravo' && (
                        <ul className="sub-menu">
                            <li><Link to={`/${i18n.language}/whats-club-bravo`} onClick={closeMenu} style={{ color: "black" }}>{t('header.what_is_club_bravo')}</Link></li>
                            <li><Link to={`/${i18n.language}/whats-club-bravo#membership-tiers`} onClick={closeMenu} style={{ color: "black" }}>{t('header.membership_tiers')}</Link></li>
                            <li><Link to={`/${i18n.language}/whats-club-bravo#members-privileges`} onClick={closeMenu} style={{ color: "black" }}>{t('header.members_privileges')}</Link></li>
                            <li><Link to={`/${i18n.language}/whats-club-bravo#enjoy-discount-at-urbtix`} onClick={closeMenu} style={{ color: "black" }}>{t('header.enjoy_discount_at_urbtix')}</Link></li>
                            <li><Link to={`/${i18n.language}/whats-club-bravo#standard-chartered-cathay-mastercard`} onClick={closeMenu} style={{ color: "black" }}>{t('header.standard_chartered_cathay_mastercard')}</Link></li>
                        </ul>
                    )}
                </li>
                <li onClick={() => toggleSubMenu('rewards')}>
                    <p className={`menu ${activeMenuItem === 'rewards' ? 'active' : ''}`}><strong> {t('header.all_rewards')} </strong> <span className={`arrow ${showSubMenu === 'rewards' ? 'flip' : ''}`}>{showSubMenu === 'rewards' ? <i className="fas fa-chevron-up"></i> : <i className="fas fa-chevron-down"></i>}</span></p>
                    {showSubMenu === 'rewards' && (
                        <ul className="sub-menu">
                            {loggedIn
                                ? (<Link to={`/${i18n.language}/asiamiles`} onClick={closeMenu} style={{ textAlign: "center", color: "black" }}>{t('header.asia_miles_conversion')}</Link>)
                                : (<Link to={`/${i18n.language}/reward-detail/asia-miles-conversion`} onClick={closeMenu} style={{ textAlign: "center", color: "black" }}>{t('header.asia_miles_conversion')}</Link>)
                            }
                            <li><Link to={`/${i18n.language}/reward-list`} onClick={closeMenu} style={{ textAlign: "center", color: "black" }}>{t('header.all_rewards')}</Link></li>
                        </ul>
                    )}
                </li>
                <li className={`menu`}><Link to={`/${i18n.language}/scan-intro`} onClick={closeMenu}><strong>{t('header.scan_your_ticket')}</strong></Link></li>
                <li className={`menu`}><Link to={`/${i18n.language}/faq`} onClick={closeMenu}><strong>{t('header.how_to')}</strong></Link></li>
                <li className={`menu`}>
                    {loggedIn ? (
                        <Link to={`/${i18n.language}/member-detail`} onClick={closeMenu}><strong>{t('header.my_account')}</strong></Link>
                    ) : (
                        <Link to={`/${i18n.language}/registration`} onClick={closeMenu}><strong>{t('registration_common.register')}</strong></Link>
                    )}
                </li>
            </ul>
            <button style={{ color: "#a72c32" }} className="mobile-menu-icon" onClick={() => setIsMobile(!isMobile)}>
                {isMobile ? (
                    <i className="fas fa-times"></i>
                ) : (
                    <i className="fas fa-bars"></i>
                )}
            </button>
        </nav>
    );
};

const mapStateToProps = state => ({
    profile: state.shared.profile,
});

export default Navbar;
