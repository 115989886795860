import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { parseFormData, parseValidationError, patchUserProfile, validateDob, validateEmail, validateMobile, validateRequired } from "../../services/helpers";
import { getUser, saveChosenRegistrationForm, updateProfile } from "../../services/user";
import About from "./About";
import ContactDetail from "./ContactDetail";
import Preference from "./Preference";
import { sharedSetItem, SharedSetItemType } from "../../store/shared";
import ReactLoadingSpinner from "../ReactLoadingSpinner";
import Popup from "../Popup";
import RegistrationContainer from "./RegistrationContainer";
import { registrationForm } from "../../services/enums";
import MembershipType from "./MembershipType";

export interface ValidationInterface {
  _custom?: ((formData: any) => string | null)[],
  [name: string]: ((name: string, value: any) => string | null)[],
}

const defaultValidations: ValidationInterface = {
  email: [validateRequired, validateEmail],
  mobile: [validateRequired, validateMobile],
  _custom: [
    formData => validateDob('date_of_birth', formData.year, formData.month),
  ]
}

const RegistrationForm = (
    {
      profile,
      setProfile,
      validations = defaultValidations,
      afterSubmit = (res) => Promise.resolve<string>(null),
      beforePatch = data => data,
      formTitle = null,
      formContent = null,
      lastStepButtonLabel = null,
      beforeNext = (current, next) => true,
      beforePrev = (current, prev) => true,
      formType = null,

      // This is from StaticContents
      registration_form_benefit,
      // required fields are detected by validation rules,
      // this is for those inside validations._custom
      // format: {[key: string]: boolean}
      labelsWithStar = {},

      // straight form = no step, no next/prev button; all steps are put on the same page
      useStraightForm = false,
      cancelCallback = () => {
      },

      // Whether to show the YA-specific fields on the form
      isYAForm = false,
    }) => {

  const { t, i18n } = useTranslation();
  const [formData, setFormData] = useState<any>({});
  const [validationError, setValidationError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState(0);
  const [requiredFields, setRequiredFields] = useState({});
  const [privacyPolicyRedirect, setPrivacyPolicyRedirect] = useState(String);
  let isInit = false;

  const steps = formType == null ? [
    {
      title: 'tier_selection',
    },
    {
      title: 'about_you',
      hint: 'benefit_scan_your_ticket_and_earn_rewards',
    },
    {
      title: 'contact_details',
      hint: 'asia_miles_conversion',
    },
    {
      title: isYAForm ? 'school_details_and_your_preference' : 'your_preference',
      hint: 'save_up_to_30_percent_on_standard_ticket_purchase',
    }
  ] : [
    {
      title: 'about_you',
      hint: 'benefit_scan_your_ticket_and_earn_rewards',
    },
    {
      title: 'contact_details',
      hint: 'asia_miles_conversion',
    },
    {
      title: isYAForm ? 'school_details_and_your_preference' : 'your_preference',
      hint: 'save_up_to_30_percent_on_standard_ticket_purchase',
    }
  ];

  useEffect(() => {
    if (i18n.language == 'tc') {
      setPrivacyPolicyRedirect('https://www.hkphil.org/tc/privacy-policy');
    } else if (i18n.language == 'sc') {
      setPrivacyPolicyRedirect('https://www.hkphil.org/sc/privacy-policy');
    } else {
      setPrivacyPolicyRedirect('https://www.hkphil.org/privacy-policy');
    }
  }, [i18n.language])

  useEffect(() => {
    // Do nothing if the profile has been submitted
    if (profile?.is_submitted_profile == registrationForm.submitted) {
      return;
    }
    else {
      let formType = null;
      switch (window.location.pathname.split('registration-')[1]) {
        case 'existing':
          formType = registrationForm.existing;
          break;
        case 'free':
          formType = registrationForm.free;
          break;
        case 'red':
          formType = registrationForm.red;
          break;
        case 'sc':
          formType = registrationForm.sc;
          break;
        case 'ya':
          formType = registrationForm.ya;
          break;
        case 'free-tier-upgrade':
          formType = registrationForm.free_tier_upgrade;
          break;
        default:
          break;
      }
      if (formType) {
        saveChosenRegistrationForm(formType);
      }
    }
  }, [profile]);

  useEffect(() => {
    let fields: any = { ...validations };

    // Iterate through each validation rule and collect fields with the "required" rule
    for (let i in fields) {
      fields[i] = fields[i].findIndex(v => v.name === validateRequired.name) > -1;
    }
    setRequiredFields({ ...fields, ...labelsWithStar });
  }, [validations]);

  useEffect(() => {
    if (!profile || isInit) return;
    isInit = true;
    setFormData(beforePatch(patchUserProfile(profile)));
  }, [profile]);

  const prevStep = () => {
    const prev = Math.max(step - 1, 0);
    if (!beforePrev(step, prev)) return;

    setStep(prev);
  }

  const nextStep = async () => {
    const next = Math.min(step + 1, steps.length - 1);
    if (!validate()) return;
    if (!beforeNext(step, next)) return;

    // If last step, submit
    if (step + 1 > steps.length - 1 || useStraightForm) {
      await submit();
    } else {
      setStep(next);
    }
  }

  const handleFormData = (e) => {
    const { name, value } = parseFormData(e);
    setFormData({ ...formData, [name]: value });
  }

  // Handles file upload by converting the event target (a file input)'s selected file
  // into a base64-encoded string and putting it in the form's given field
  const handleFileUpload = (e, fieldName) => {
    // Show spinner
    setIsLoading(true);

    // Prepare a reader that converts the file content into a base64 string
    let reader = new FileReader();
    reader.onload = readerOnLoadEvent => {
      setFormData({ ...formData, [fieldName]: readerOnLoadEvent.target.result });
      setIsLoading(false);
    }

    // Read the file
    reader.readAsDataURL(e.target.files[0])
  }

  const submit = async () => {
    // To prevent duplicate submission
    if (isLoading) {
      return;
    }

    // Show spinner
    setIsLoading(true);

    try {

      // Indicate if this is a YA form - because the handling on the server side is vastly different
      const res = await updateProfile({
        ...formData,
        _is_ya_form: isYAForm,
        _is_paying_red: window.location.pathname.endsWith('registration-red'),
      });
      if (res?.success) {
        const profile = await getUser();
        await setProfile(profile);
      } else {
        const error = parseValidationError(res?.error?.message);
        setValidationError(error);
      }

      setIsLoading(false);

      const afterSubmitError = await afterSubmit(res);
      if (afterSubmitError) {
        setValidationError(afterSubmitError);
      }

    } catch (ex) {
      console.error(ex);
      setValidationError(ex.message);
      setIsLoading(false);
    }
  }

  const validate = () => {
    if (formType == null && step === 0) {
        return true;
    }
    let errors = [];

    for (let name in validations) {
      if (name === '_custom') {
        errors = errors.concat(validations._custom.map(v => v(formData)));
      } else {
        errors = errors.concat(validations[name].map(v => v(name, formData[name])));
      }
    }

    errors = errors.filter(v => !!v);
    if (errors.length > 0) {
      setValidationError(errors.join('<br />'));
      return false;
    } else {
      return true;
    }
  }

  return (
    <>
        { validationError 
        // && step == 2 
        && <Popup
        title={''} // temp removed error.validation_error
        message={validationError}
        buttons={[
          {
            label: t('button.close'),
            callback: () => {
              setValidationError(null);
            }
          }
        ]}
      />}

      {isLoading && <ReactLoadingSpinner />}

      <RegistrationContainer steps={steps} step={step} useStraightForm={useStraightForm}>
        <h2 className="title1">{formTitle || t('registration_common.join_club_bravo')}</h2>
        <h1 className="title2">{formContent || t('registration_common.free_member_registration')}</h1>

        {!useStraightForm && (
          // Show the step titles and hints
          <>
            <div className="formStep">
              {steps.map((v, i) => (
                <div key={i} className={`steps step1 ${step === i ? 'active' : ''}`} >
                  <div className="num">
                    <p className="title1">{i + 1}</p>
                  </div>

                  <p className="title1" style={{ marginLeft: '-10px' }}>{t(`registration_common.${v.title}`)}</p>
                </div>
              ))}
            </div>
            <div className="formDesc mobileOn">
              <div className="part2 col-12">
                {
                  registration_form_benefit?.map((item, i) => (
                    <div className='con' key={i} dangerouslySetInnerHTML={{ __html: item.content[i18n.language] }}></div>
                  ))
                }
              </div>
              <div className="part1 col-12">
                <p>{t('landing_page.please_fill_out')}</p>
              </div>
            </div>
          </>
        )}

        <form>
          {/* 4-page layout; each page is a component */}
          {formType == null && (
            <MembershipType
              active={useStraightForm || step === 0}
              formData={formData}
              handleFormData={handleFormData}
              handleFileUpload={handleFileUpload}
            />
          )}
          <About
            active={useStraightForm || step === (formType == null ? 1 : 0)}
            formData={formData}
            requiredFields={requiredFields}
            handleFormData={handleFormData}
            isYAForm={isYAForm}
          />
          <ContactDetail
            active={useStraightForm || step === (formType == null ? 2 : 1)}
            formData={formData}
            requiredFields={requiredFields}
            handleFormData={handleFormData}
          />
          <Preference
            active={useStraightForm || step === (formType == null ? 3 : 2)}
            formData={formData}
            requiredFields={requiredFields}
            handleFormData={handleFormData}
            handleFileUpload={handleFileUpload}
            isYAForm={isYAForm}
          />

          {/* Last page */}
          {step === steps.length - 1 && (
            <div className="formCol col-12">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="confirm_email"
                  checked={formData?.confirm_email || false}
                  onChange={handleFormData} />
                <label className="form-check-label" htmlFor="formCheck-5">
                  {t('registration_step_three.i_confirm_email_correct')} {profile.email}
                </label>
              </div>
              <br/>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="consent"
                  checked={formData?.consent || false}
                  onChange={handleFormData} />
                <label className="form-check-label" htmlFor="formCheck-5">
                  {t('registration_step_three.i_agree_to_the_hk_phil_1')}
                  <a href={privacyPolicyRedirect}>{t('registration_step_three.privacy_policy')}</a>
                  {t('registration_step_three.i_agree_to_the_hk_phil_2')}
                  <Link to={`/${i18n.language}/terms-and-conditions`} >
                    {t('registration_step_three.terms_of_conditions')}
                  </Link>
                </label>
              </div>
            </div>
          )}

          <div className="col-12 requiredTxt mb-5">
            <p>{t('registration_message.required_field')}</p>
          </div>

          {/* Prev / next buttons */}
          {!useStraightForm && (
            <div className="col-12">
              <div className="formCol d-flex justify-content-end w-100">
                {step > 0 && (
                  <button
                    type="button"
                    className="btn btn-cancel mb-3 mb-sm-0 me-sm-3 w-sm-50 prevStep"
                    onClick={prevStep}
                  >
                    {t('registration_common.previous_step')}
                  </button>
                )}
                <button
                  type="button"
                  className="btn nextStep w-sm-50"
                  onClick={nextStep}
                >
                  {(step >= steps.length - 1)
                    ? (lastStepButtonLabel || t('registration_common.submit'))
                    : t('registration_common.next_step')
                  }
                  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16" className="bi bi-arrow-right icons">
                    <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"></path>
                  </svg>
                </button>
              </div>
            </div>
          )}

          {/* "Submit / back buttons - only for straight forms */}
          {useStraightForm && (
            <div className="col-12 col-sm-6 formCol d-flex ms-auto">
              <button type="button" className="btn me-sm-3 mb-3 mb-sm-0" onClick={nextStep}>
                {t('button.submit')}
              </button>
              <button type="button" className="btn btn-cancel" onClick={cancelCallback}>
                {t('button.back')}
              </button>
            </div>
          )}
        </form>
      </RegistrationContainer>
    </>
  );
};

const mapStateToProps = state => ({
  profile: state.shared.profile,
  registration_form_benefit: state.shared.registration_form_benefit,
});

const mapDispatchToProps = (dispatch) => ({
  setProfile: (value) => dispatch(sharedSetItem(SharedSetItemType.profile, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationForm);
